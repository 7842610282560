.backToFirstPageContainer button {
    border-radius: 50px;
    margin-top: 2rem;
    background: #ff0000;
    border: 1px solid #ffffff;
    color: #ffffff;
    width: 100px;
    outline: none;
}

#formSectionConcreteBlocks {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    background: #ff0000;
}

#formConcreteBlocks {
    display: flex;
    flex-direction: column;
}

#formHeaderConcreteBlocks {
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
}

#formInputsConcreteBlocks {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    background-color: transparent;
    border-bottom: none;
    width: 60%;
    margin-left: 23%;
}

#formInputsConcreteBlocks .selectInput {
    width: 350px;
}

#formInputsConcreteBlocks .form-group {
    margin-right: 2rem;
}

#formInputsConcreteBlocks label {
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
}

#formInputsConcreteBlocks select {
    border: 1px solid #ffffff;
    background: none;
    color: #ffffff;
}

#formInputsConcreteBlocks select option {
    color: initial;
}

#formInputsConcreteBlocks input {
    border: 1px solid #ffffff;
    background: none;
    color: #000000;
}

#formInputsConcreteBlocks button {
    background: #ff0000;
    color: #ffffff;
    box-shadow: none;
    border: 1px solid #ffffff;
    border-radius: 50px;
    width: 150px;
    line-height: inherit;
    height: 38px;
    margin-top: 11px;
}

.fa-paper-plane {
    margin-right: 10px;
}

.invisibleHeightSelectContainer {
    opacity: 0;
}