#responseVisualisations {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.responseVisualisationHeader {
    font-size: 1.4rem;
    color: #ff0000;
    text-transform: uppercase;
}

.responseVisualisationImg {
    height: 100px;
}

.responseVisualisationIcon {
    transform: translateX(-20%);
    color: #ff0000;
    border-radius: 100%;
    border: 1px solid #ff0000;
    width: 24px !important;
    height: 24px !important;
    padding: 4px;
}

.responseVisualisationClearance {
    display: flex;
    font-size: .9rem;
    margin-top: 1rem;
}

.responseVisualisationClearanceNumber {
  color: #ff0000;
}

#responseGateSelfSupporting {
    width: 334px;
}

#responseGateSelfSupporting .result {
    display: flex;
    justify-content: flex-start;
}

#responseClosestHeightGateSelfSupporting {
    width: 334px;
}

#responseGateTilting, #responseClosestHeightGateTilting {
    width: 250px;
}

#responseGateway, #responseClosestHeightGateway {
    width: 105px;
}

#responseSpan, #responseClosestHeightSpan {
    width: 170px;
}

#responseSuggestedHeight {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: .6rem;
}
