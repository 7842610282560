.backToFirstPageContainer button {
  border-radius: 50px;
  margin-top: 2rem;
  background: red;
  border: 1px solid white;
  color: white;
  width: 100px;
  outline: none;
}

#formSection {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #ff0000;
}

#form {
  display: flex;
  flex-direction: column;
}

#formHeader {
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}

#formInputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  background-color: transparent;
  border-bottom: none;
  width: 60%;
  margin-left: 20%;
}

.form-group {
  width: 300px;
}

#formInputs .form-group {
  margin-right: 2rem;
}

#formInputs label {
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
}

#formInputs select {
  border: 1px solid #ffffff;
  background: none;
  color: #ffffff;
}

#formInputs select option {
  border: 1px solid #ffffff;
  background: none;
  color: #000000;
}

#formInputs input {
  border: 1px solid #ffffff;
  background: none;
  color: #ffffff;
}

#formInputs button {
  background: #ff0000;
  color: #ffffff;
  box-shadow: none;
  border: 1px solid #ffffff;
  border-radius: 50px;
  width: 150px;
  line-height: inherit;
  height: 38px;
  margin-top: 13px;
}

.form-control::-moz-placeholder {
  color: #ffffff;
}
.form-control:-ms-input-placeholder {
  color: #ffffff;
}
.form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.fa-paper-plane {
  margin-right: 10px;
}