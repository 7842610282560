#footer {
    align-items: center;
    font-size: 12px;
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
}

#footerRow {
    align-items: center;
}

#footerRights {
    text-align: center;
}

#footerCompany p {
    text-align: right;
    margin: 0;
}

#footerLogoContainer {
    text-align: left;
}

#footerLogoContainer img {
    width: 300px;
    height: auto;
}
