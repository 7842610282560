#closestHeights {
  background: #e0e0e0;
  padding: 1.5rem 2.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.closestHeight {
  position: relative;
  font-size: 1rem;
}

.closestHeight:before {
  position: absolute;
  bottom: 112px;
  border-bottom: 20px solid #e0e0e0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  content: '';
  margin-left: 1rem;
}

.closestHeightNumber {
  color: #ff0000;
  font-weight: 600;
}

#closestHeightGateSelfSupporting {
  width: 334px;
}

#closestHeightGateTilting {
  width: 250px;
}

#closestHeightGateway {
  width: 105px;
}

#closestHeightSpan {
  width: 170px;
  margin-right: 126px;
}
