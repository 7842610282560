.gettingStartedHeader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    background: #ff0000;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 40px;
    padding: 30px;
}

#gettingStartedPageChoiceForm {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#gettingStartedPageChoiceForm > button {
    width: 400px;
    height: 250px;
    margin: 75px;
    font-size: 20px;
    padding: 30px;
    border: 2px solid #ff0000;
    border-radius: 20px;
    background: #ffffff;
}

#gettingStartedPageChoiceForm > button:focus {
    outline: none;
}

.containerForMargin {
    margin-top: 100px;
}