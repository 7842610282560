#rangeBtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 13rem;
}

.rangeBtn {
  border-radius: 100%;
  border: 1px solid #ff0000;
  margin-left: 1.2rem;
}

.rangeBtn svg {
  color: #ff0000;
}
